@import "variables";

// ambition colour scheme
body.theme-default,
body.theme-blue {
    
  .btn-primary {
    background-color: $ambition-primary !important;
    border-color: $ambition-primary !important;
    color: #ffffff !important;
  }

  .btn-secondary {
    background-color: $ambition-secondary !important;
    border-color: $ambition-secondary !important;
    color: #ffffff !important;
  }

  .navbar,
  .navbar-main {
    background-color: $ambition-primary !important;
    border-color: $ambition-primary !important;

    .dropdown-menu {
      > li > a:hover {
        background: rgba($ambition-primary, 0.6) !important;
      }
      > li.active > a {
        background: rgba($ambition-primary, 0.6) !important;
      }
    }
  }

  a.kq_search,
  span.stat-num {
    color: $ambition-primary !important;
  }

  .text-teal-600,
  .text-teal-800,
  .text-teal-800:hover,
  .text-teal-800:focus {
    color: $ambition-primary !important;
  }

  .bg-teal {
    background-color: $ambition-secondary !important;
    border-color: $ambition-secondary !important;
    color: #fff;
  }

  .bg-grey {
    background-color: #626262 !important;
    border-color: #626262 !important;
    color: #fff !important;
  }

  .bg-grey-600,
  .bg-grey-800 {
    background-color: #626262 !important;
    border-color: #626262 !important;
    color: #fff !important;
  }

  .border-top-teal-400 {
    border-top-color: $ambition-primary !important;
  }

  .border-bottom-teal-400 {
    border-bottom-color: $ambition-primary !important;
  }

  .border-left-teal-400 {
    border-left-color: $ambition-primary !important;
  }

  .border-right-teal-400 {
    border-left-color: $ambition-primary !important;
  }

  .text-teal-400,
  .text-teal-400:hover,
  .text-teal-400:focus {
    color: $ambition-primary !important;
  }

  .bg-teal-200,
  .bg-teal-400,
  .bg-teal-800 {
    background-color: $ambition-primary !important;
    border-color: $ambition-primary !important;
    color: #fff !important;
  }

  .bg-teal-600 {
    background-color: $ambition-secondary !important;
    border-color: $ambition-secondary !important;
    color: #fff !important;
  }

  .border-teal-400 {
    border-color: $ambition-primary !important;
  }

  .bg-info-800 {
    background-color: $ambition-secondary !important;
    border-color: $ambition-secondary !important;
    color: #fff !important;
  }

  .nav-tabs {
    border-color: $ambition-primary;
    &.nav-tabs-top > li.active > a,
    &.nav-tabs-top > li > a:hover {
      transition: all 0.4s ease;
      border-color: $ambition-primary !important;
    }
  }

  .pagination > .active > span {
    background: $ambition-primary !important;
  }

  .checker span.checked {
    background: $ambition-primary !important;
    border-color: $ambition-primary !important;
  }

  .ul.modern-listing li i,
  .table-row-icon {
    color: $ambition-secondary !important;
  }

  .progress-bar-info {
    background: $ambition-secondary !important;
    border-color: $ambition-secondary !important;
  }

  .list-body {
    h4 {
      color: $ambition-primary !important;
    }
  }

  .campaign-steps {
    border-bottom: dashed 2px $ambition-primary !important;
    li {
      &.active {
        a {
          background-color: #fff;
          border-bottom: solid 2px $ambition-primary !important;
          border-radius: 5px 5px 0 0;
          background: $ambition-primary !important;
          padding: 10px 15px;
          margin-bottom: -2px;
          color: #fff;
        }
      }
      &:not(.active) {
        a {
          &:hover {
            background-color: #fff;
            color: $ambition-primary !important;
          }
        }
      }
    }
  }

  .select2-results__option[aria-selected="true"] {
    background: $ambition-primary !important;
  }

  h1 {
    color: $ambition-primary;
    text-transform: uppercase;
  }
}
